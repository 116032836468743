import { ref, computed, watch } from '@vue/composition-api'
import {
  getFacilitiesActive,
  getRecordings,
  getRecordingVideoOrder,
  getVideoStartTime,
  reportIssue,
  getFieldsByFacility,
} from '@api'
import { useRouter } from '@core/utils'
import { success } from '@/@core/utils/toasted'
import moment from 'moment'

export default function useVideos() {
  const { router } = useRouter()

  const facilities = ref([])
  const fieldsOptions = ref([])
  const fields = ref([])
  const recordings = ref([])
  const hasRecordings = ref(true)
  const lastItemId = ref(0)
  const canRefresh = ref(true)
  const pageSize = ref(0)
  const timeOptions = ref([
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
  ])
  const startTimes = ref(timeOptions.value)

  const minDate = ref(
    new Date(+new Date() - 12096e5).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const maxDate = ref(
    new Date(new Date()).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const isRefreshRoute = ref(false)
  const modal = ref(null)
  const dateFilter = ref(null)
  const fieldFilter = ref(null)
  const timeFilter = ref(null)
  const busy = ref(true)
  const timerRefresh = ref(null)
  const timerFetch = ref(null)

  const search = ref(null)
  const facilitySelected = ref(null)
  const facilityIdSelected = ref(null)

  const isDialogReport = ref(false)

  const formatDate = date => {
    if (!date) return null

    return moment(date).format('DD MMM')
  }

  const computedDateFormatted = computed({
    get: () => formatDate(dateFilter.value),
    set: val => {
      dateFilter.value = val
    },
  })

  const onReportIssue = async (val, email) => {
    let textReport = ''
    if (email) textReport += `Email: ${email}, `
    if (facilityIdSelected.value) {
      const facilityFinded = facilities.value.find(e => e.id === facilityIdSelected.value)
      textReport += `Facility: ${facilityFinded.name}, `
    }
    if (fieldFilter.value) {
      const fieldFinded = fields.value.find(e => e.id === fieldFilter.value)
      textReport += `Field: ${fieldFinded.name}, `
    }
    if (dateFilter.value) textReport += `Date: ${dateFilter.value}, `
    if (timeFilter.value) textReport += `Start Time: ${timeFilter.value}, `
    textReport += `Message: ${val}`
    const resp = await reportIssue('null', textReport)
    if (resp.ok) {
      isDialogReport.value = false
      success(resp.message)
    }
  }

  const fetchFacilities = async () => {
    try {
      const resp = await getFacilitiesActive()
      if (resp.ok) facilities.value = resp.data
      else facilities.value = []
    } catch (e) {
      console.error(e)
      facilities.value = []
    }
  }

  const fetchFields = async facilityId => {
    const response = await getFieldsByFacility(facilityId)
    fieldsOptions.value = response.data
  }

  const fetchStartTime = async () => {
    try {
      const resp = await getVideoStartTime(fieldFilter.value, dateFilter.value)
      if (resp.ok) {
        startTimes.value = resp.data
        if (!startTimes.value.includes(timeFilter.value)) timeFilter.value = null
      } else {
        startTimes.value = timeOptions.value
        timeFilter.value = null
      }
    } catch (e) {
      console.error(e)
      startTimes.value = timeOptions.value
    }
  }

  const fetchRecordingsOrders = async () => {
    try {
      clearTimeout(timerFetch.value)
      timerFetch.value = setTimeout(async () => {
        if (facilityIdSelected.value) {
          const respRecording = await getRecordingVideoOrder(
            0,
            facilityIdSelected.value || 0,
            fieldFilter.value || 0,
            dateFilter.value || maxDate.value,
            timeFilter.value || 0,
          )
          if (respRecording.ok) {
            recordings.value = respRecording.data
          }
        } else router.push({ name: 'views-facility-list' })
      }, 200)
    } catch (e) {
      console.error(e)
    }
  }

  const fetchRecordings = async () => {
    try {
      if (canRefresh.value && lastItemId.value !== null) {
        clearTimeout(timerFetch.value)
        timerFetch.value = setTimeout(async () => {
          if (facilityIdSelected.value) {
            const respRecording = await getRecordings(
              facilityIdSelected.value || 0,
              fieldFilter.value || 'all',
              dateFilter.value || 'all',
              timeFilter.value || 'all',
              lastItemId.value,
            )
            if (respRecording.ok) {
              lastItemId.value = respRecording.data.last_item_id
              pageSize.value = respRecording.data.page_size
              canRefresh.value = respRecording.data.has_next_page
              const newItems = respRecording.data.items
              recordings.value = recordings.value.concat(newItems)
              hasRecordings.value = !!recordings.value.length
            }
          } else router.push({ name: 'views-facility-list' })
        }, 200)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onLoadMore = async () => {
    busy.value = true
    clearTimeout(timerRefresh.value)
    timerRefresh.value = setTimeout(async () => {
      // await fetchRecordings()
      await fetchRecordingsOrders()
      busy.value = false
    }, 600)
  }

  // watch([dateFilter, fieldFilter], async () => {
  //   if (dateFilter.value && fieldFilter.value) await fetchStartTime()
  //   else startTimes.value = timeOptions.value
  // })

  const refreshRoure = () => {
    isRefreshRoute.value = true
    setTimeout(() => { isRefreshRoute.value = false }, 200)
  }

  watch([dateFilter, fieldFilter, timeFilter, isRefreshRoute], async () => {
    clearTimeout(timerFetch.value)
    timerFetch.value = setTimeout(async () => {
      lastItemId.value = 0
      recordings.value = []
      canRefresh.value = true

      router.replace({
        name: 'views-video',
        params: {
          facility: facilityIdSelected.value || 'all',
          field: fieldFilter.value || 'all',
          date: dateFilter.value || 'all',
          time: timeFilter.value || 'all',
        },
      }).catch(() => {})

      // await fetchRecordings()
      await fetchRecordingsOrders()
    }, 300)
  })

  return {
    facilities,
    fieldsOptions,
    fields,
    timeOptions,
    recordings,
    hasRecordings,
    startTimes,
    lastItemId,
    pageSize,
    busy,
    computedDateFormatted,

    search,
    facilitySelected,
    facilityIdSelected,

    isDialogReport,

    minDate,
    maxDate,

    modal,
    dateFilter,
    fieldFilter,
    timeFilter,

    fetchFacilities,
    fetchFields,
    fetchRecordings,
    fetchRecordingsOrders,
    fetchStartTime,
    onLoadMore,
    onReportIssue,
    formatDate,
    refreshRoure,
  }
}
