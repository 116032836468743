<template>
  <div class="auth-wrapper auth-v1 align-start py-0">
    <div class="auth-inner">
      <v-card-text
        :style="{
          backgroundColor: '#f2f2f2',
        }"
      >
        <v-row class="px-0 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-0 d-flex justify-space-between align-center"
          >
            <h2 class="black--text ml-1">
              {{ t('Videos') }}
            </h2>

            <div>
              <v-btn
                v-if="!privateLanding"
                class="ma-0 py-2 px-0 no-bg-hover"
                style="
                  text-transform: inherit;
                  min-width: 60px;
                "
                text
                color="secondary"
                @click="onLogin()"
              >
                {{ t('login.login') }}
              </v-btn>

              <app-bar-user-avatar
                v-else
                class="ms-2"
                :show-report="true"
                @logout="onLogout"
                @report="openCloseReport"
              />
            </div>
          </v-col>

          <v-col
            cols="12"
            class="px-0"
          >
            <v-select
              v-model="facilityIdSelected"
              :placeholder="t('facilities.search_facility')"
              :items="facilities"
              item-text="name"
              item-value="id"
              outlined
              dense
              readonly
              hide-details
              @focus="$router.replace({ name: 'views-facility-list' })"
            >
              <template v-slot:selection="{ item }">
                <img
                  :src="item.logo"
                  :alt="item.name"
                  width="25"
                  height="auto"
                  class="mr-2 img-rounded"
                >
                {{ item.name }}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pt-3 pb-0">
        <v-row
          class="px-0 py-1 ma-0"
          justify="center"
        >
          <v-col
            cols="12"
            class="py-1 px-0 d-flex justify-space-between"
          >
            <v-menu
              v-model="modal"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  class="w-30"
                  :placeholder="t('videos.date')"
                  :prepend-inner-icon="!dateFilter ? icons.mdiCalendarOutline : null"
                  outlined
                  readonly
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                />
                <!-- clearable
                @click:clear="() => dateFilter = null" -->
              </template>
              <v-date-picker
                v-model="dateFilter"
                color="primary"
                :max="maxDate"
                :min="minDate"
                @input="modal = false"
              />
            </v-menu>

            <v-select
              v-model="fieldFilter"
              :placeholder="t('fields.field')"
              :items="fields"
              item-value="id"
              item-text="name"
              class="w-30"
              dense
              outlined
              clearable
              :prepend-inner-icon="!fieldFilter ? icons.mdiSoccerField : null"
              :append-icon="null"
              hide-details
            />

            <v-select
              v-model="timeFilter"
              :placeholder="t('fields.hour')"
              :items="startTimes"
              class="w-30"
              dense
              outlined
              clearable
              :prepend-inner-icon="!timeFilter ? icons.mdiClockOutline : null"
              :append-icon="null"
              hide-details
            />
          </v-col>

          <v-col
            cols="12"
            class="pt-3 pb-0 px-2"
          >
            <span>
              <v-icon class="mr-2 text-caption"> {{ icons.mdiInformationOutline }}</v-icon>
              <span>{{ t('facilities.recording_weeks') }}</span>
            </span>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- v-infinite-scroll="onLoadMore" -->
      <v-card-text
        class="py-3"
        :infinite-scroll-disabled="true"
        infinite-scroll-distance="10"
      >
        <v-row
          v-if="recordings.length"
          justify="center"
        >
          <v-col
            v-for="(video, i) in recordings"
            :key="i"
            cols="12"
            class="align-self-start p-card"
          >
            <v-card
              :color="$vuetify.theme.dark ? '#1d1d1d' : '#fff'"
              class="rounded-lg border-0"
              elevation="15"
              height="auto"
            >
              <v-carousel
                height="205px"
                width="auto"
                hide-delimiters
                show-arrows-on-hover
                cycle
                :show-arrows="false"
              >
                <div @click="onWatchVideo(video)">
                  <v-carousel-item
                    v-if="video.entity === 'video' && video.thumbnail && !video.thumbnail.includes('no-thumb')"
                    :src="video.thumbnail || ''"
                    eager
                    class="cursor-pointer bg-grey"
                    :style="(video.entity !== 'video') ? 'opacity: 0.5;' : ''"
                  >
                  </v-carousel-item>

                  <div v-else-if="video.thumbnails && video.thumbnails.some(e => !e.includes('no-thumb'))">
                    <v-carousel-item
                      v-for="(item, index) in video.thumbnails.filter(e => !e.includes('no-thumb'))"
                      :key="index"
                      :src="item"
                      eager
                      class="cursor-pointer bg-grey"
                      :style="video.entity !== 'video' ? 'opacity: 0.5;' : ''"
                    >
                    </v-carousel-item>
                  </div>

                  <v-carousel-item
                    v-else
                    eager
                    class="cursor-pointer bg-grey"
                    :style="video.entity !== 'video' ? 'opacity: 0.5;' : ''"
                  >
                  </v-carousel-item>

                  <video-chips
                    :time="video.video_time"
                    :is-premium="video.video_type === 'T'"
                    :show-time="video.entity === 'video'"
                    :show-label="video.entity === 'video'"
                    :show-center-icon="video.entity !== 'video'"
                  />
                </div>
              </v-carousel>

              <v-card-text class="px-3 py-2">
                <div
                  class="ms-0 width-full"
                >
                  <v-row class="pa-1 ma-0">
                    <v-col
                      cols="12"
                      class="pa-1 d-flex justify-space-between align-center"
                    >
                      <div
                        class="d-flex cursor-pointer"
                        @click="onWatchVideo(video)"
                      >
                        <div class="d-flex flex-column">
                          <span class="text-body-2 black--text pb-2">{{ video.entity === 'video' ? formatVideoName(`${video.register_date} ${video.video_start_time}`) : formatVideoName(`${video.video_date} ${video.start_time}`) }}</span>
                          <span class="text-xs">{{ `${facilitySelected.name} • ${video.field_name}` }}</span>
                        </div>
                      </div>
                      <div
                        v-if="video.entity === 'video'"
                        class="d-flex flex-column cursor-pointer"
                        @click="onShare(video)"
                      >
                        <v-icon
                          color="black"
                        >
                          {{ icons.mdiShareOutline }}
                        </v-icon>
                        <span class="text-xs black--text">{{ t('tooltip.share') }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row
          v-else
          class="mt-16"
        >
          <v-col
            class="text-center mt-16"
          >
            <h3 class="black--text pb-2">
              {{ t('videos.no_recordings') }}
            </h3>
            <span class="px-5">{{ t('videos.no_recordings_detail') }}
              <span
                class="secondary--text text-lowercase cursor-pointer"
                @click="openCloseReport(true)"
              >
                {{ t('transactions.contact_us') }}
              </span>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <report
      v-model="isDialogReport"
      @open-close="openCloseReport"
      @report-issue="onReportIssue"
    />

    <share
      :is-open.sync="isDialogShare"
      :link="shareData.link"
      :icon-size="28"
      :sport="shareData.sport"
      :date="shareData.date"
      :start-time="shareData.startTime"
      :location="shareData.location"
      @open-close="openUpdateShare"
    />
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref, onBeforeMount, onMounted } from '@vue/composition-api'
import {
  mdiCalendarOutline,
  mdiSoccerField,
  mdiClockOutline,
  mdiInformationOutline,
  mdiShareOutline,
} from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatCurrency } from '@core/utils'
import themeConfig from '@themeConfig'
import moment from 'moment'

import Vue from 'vue'
import infiniteScroll from 'vue-infinite-scroll'

import useLogData from '@core/utils/useLogData'
import useVideoData from '@core/utils/useVideoData'
import useCryptoJs from '@core/utils/useCryptoJs'
import useShare from '@/@core/utils/useShare'

import useLanding from '@/views/landing/useLanding'

import AppBarUserAvatar from '@core/layouts/components/app-bar/AppBarUserAvatar.vue'
import Report from '@/views/landing/components/report/Report.vue'
import Share from '@/views/components/share/Share.vue'
import VideoChips from './VideoChips.vue'

import useVideos from './useVideos'

export default {
  components: {
    AppBarUserAvatar,
    Report,
    VideoChips,
    Share,
  },
  setup() {
    const { t } = useUtils()
    const { router, route } = useRouter()
    const { userData } = useCryptoJs()
    const { logData, updateLogData, clearLogData } = useLogData()
    const { videoData, currentVideoData, updateVideoData } = useVideoData()
    const {
      facilities,
      fields,
      startTimes,
      recordings,
      hasRecordings,
      lastItemId,
      busy,
      isDialogReport,

      facilitySelected,
      facilityIdSelected,

      minDate,
      maxDate,
      modal,
      dateFilter,
      fieldFilter,
      timeFilter,
      computedDateFormatted,

      fetchFacilities,
      onLoadMore,
      onReportIssue,
      refreshRoure,
    } = useVideos()

    const {
      paymentMethods,
      fetchPaymentMethods,
    } = useLanding()
    const { shareURL, isSupportedShare } = useShare()

    const isDialogShare = ref(false)
    const shareData = ref({})
    const privateLanding = ref(false)

    const formatVideoName = videoDate => moment(`${videoDate}`, 'YYYY-MM-DD hh:mm').format('dddd HH:mm, MMM DD')

    const onLogin = async () => {
      logData.value.joinGame = 'NO'
      logData.value.matchCode = null
      logData.value.redirect = 'videos'
      logData.value.facility = facilitySelected.value
      logData.value.field = fieldFilter.value || 'all'
      logData.value.date = dateFilter.value || 'all'
      logData.value.time = timeFilter.value || 'all'
      await updateLogData(logData.value)

      router.push({ name: 'auth-login-phone-email' })
    }

    const onLogout = async () => {
      await localStorage.removeItem('userData')
      privateLanding.value = false
    }

    const onLoadVideo = async video => {
      if (privateLanding.value) await fetchPaymentMethods()
      videoData.value.video = video
      videoData.value.match = null
      videoData.value.price = video.video_price
      videoData.value.paymentMethodsParams = paymentMethods.value
      await updateVideoData(videoData.value)
    }

    const onWatchVideo = async video => {
      if (video.entity === 'video') {
        if (!video.show_video_type) {
          videoData.value.video = video
          await updateVideoData(videoData.value)

          const hash = video.video_share_url.split('/r/')[1]
          router.push({
            name: 'recording',
            params: {
              hash,
            },
          })
        } else {
          await onLoadVideo(video)
          router.push({ name: 'views-video-quality' })
        }
      } else {
        await onLoadVideo(video)
        router.push({ name: 'views-video-checkout' })
      }
    }

    const openUpdateShare = async val => {
      isDialogShare.value = val
    }

    const onShare = video => {
      try {
        if (isSupportedShare.value) {
          shareURL(
            'Fanaty',
            `Date: ${formatVideoName(`${video.register_date} ${video.video_start_time}`)}\nTime: ${video.video_start_time} (${video.video_min_long} mins)\nLocation: ${video.facility_name} • ${video.field_name}\n\nManage booking:\n${video.video_share_url}\n\nVideo recording available via the link.`,
            video.video_share_url,
          )
        } else {
          shareData.value = {
            link: video.video_share_url,
            sport: `${facilitySelected.value.name} • ${video.field_name}`,
            date: formatVideoName(`${video.register_date} ${video.video_start_time}`),
            startTime: `${video.video_start_time} (${video.video_min_long} mins)`,
            location: `${facilitySelected.value.name} • ${video.field_name}`,
          }
          openUpdateShare(true)
        }
      } catch (e) {
        console.error(e)
        shareData.value = {
          link: video.video_share_url,
          sport: `${facilitySelected.value.name} • ${video.field_name}`,
          date: formatVideoName(`${video.register_date} ${video.video_start_time}`),
          startTime: `${video.video_start_time} (${video.video_min_long} mins)`,
          location: `${facilitySelected.value.name} • ${video.field_name}`,
        }
        openUpdateShare(true)
      }
    }

    const openCloseReport = async val => {
      isDialogReport.value = val
    }

    const isPaass = (dateA, dateB) => {
      const date1 = new Date(dateA)
      const date2 = new Date(dateB)

      return date1 < date2
    }

    const isFuture = (dateA, dateB) => {
      const date1 = new Date(dateA)
      const date2 = new Date(dateB)

      return date1 > date2
    }

    onBeforeMount(() => {
      Vue.directive('infinite-scroll', infiniteScroll)
    })

    onMounted(async () => {
      await localStorage.removeItem('matchCode')
      const {
        facility,
        field,
        date,
        time,
      } = route.value.params

      privateLanding.value = !!userData.value

      Object.assign(videoData.value, currentVideoData.value)
      await clearLogData()
      if (facility && facility !== 'all' && field && date && time) {
        const pass = isPaass(date, minDate.value)
        const future = isFuture(date, maxDate.value)

        // const dateStr = date !== 'all' ? pass ? minDate.value : date : maxDate.value
        let dateStr = maxDate.value
        if (date !== 'all') {
          if (date) dateStr = date
          if (pass) dateStr = minDate.value
          if (future) dateStr = maxDate.value
        }
        const facilityId = facility !== 'all' ? parseInt(facility, 10) : null
        const fieldId = field !== 'all' ? parseInt(field, 10) : null
        const timeStr = time !== 'all' ? time : null
        refreshRoure()

        await fetchFacilities()
        const facilityFinded = facilities.value.find(e => e.id === facilityId)
        if (facilityFinded) {
          facilityIdSelected.value = facilityId
          facilitySelected.value = facilityFinded
          fields.value = facilityFinded.fields
        }
        fieldFilter.value = fieldId
        dateFilter.value = dateStr
        timeFilter.value = timeStr
      } else if (videoData.value.facility && videoData.value.facility.id) {
        facilityIdSelected.value = videoData.value.facility.id
        facilitySelected.value = videoData.value.facility
        fields.value = videoData.value.facility.fields

        await fetchFacilities()
      } else router.push({ name: 'views-facility-list' })
    })

    return {
      // data
      shareData,
      isDialogShare,
      privateLanding,
      isDialogReport,
      userData,
      facilitySelected,
      facilityIdSelected,
      facilities,
      fields,
      startTimes,
      recordings,
      hasRecordings,
      lastItemId,

      minDate,
      maxDate,
      modal,
      dateFilter,
      fieldFilter,
      timeFilter,
      computedDateFormatted,

      busy,
      onLoadMore,

      // methods
      formatCurrency,
      formatVideoName,
      onLogin,
      onLogout,
      onWatchVideo,
      openCloseReport,
      onReportIssue,
      openUpdateShare,
      onShare,

      fetchFacilities,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      t,

      icons: {
        mdiCalendarOutline,
        mdiSoccerField,
        mdiClockOutline,
        mdiInformationOutline,
        mdiShareOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
@import '@core/preset/preset/pages/landing.scss';

.v-select__selections > input::placeholder {
  color: #3f3f3f !important
}
</style>
<style lang="scss" scoped>
.app-content-container {
  padding: 0px !important;
}

.img-rounded {
  border-radius: 50px;
}

.border-0 {
  border: 0px solid transparent !important;
}

.v-text-field--outlined {
  border-radius: 15px;
}

.v-text-field--outlined .v-input__control .v-input__slot {
  background-color: #333333 !important;
}

.w-30 {
  max-width: 32%;
}

div.v-input.w-30 {
  font-size: 14px;
}

.bg-grey {
  background-color: #555555;
}
</style>
